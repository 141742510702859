<!--
* @description keyy简介
* @fileName keyyjianjie.vue
* @author liulian
* @date 2022/06/16 09:10:50
-->
<template>
  <div class="keyy_con screenWidth" v-html="webInfo.web_info_content"></div>
</template>

<script>
import { webInfoApi } from "@/api/api.js"
export default {
  name: "keyyjianjie",
  components: {},
  data() {
    return {
      webInfo: {},
    }
  },
  computed: {},
  created() {
    this.getWebInfo()
  },
  mounted() {},
  methods: {
    getWebInfo() {
      webInfoApi().then((res) => {
        if (res.code === 10000) {
          this.webInfo = res.data
        }
      })
    },
  },
}
</script>

<style scoped lang="scss">
.keyy_con {
  margin: 1.875rem auto;
  padding-bottom: 4.125rem;
  overflow: hidden;
  ::v-deep p {
    img {
      width: 100%;
    }
  }
  ::v-deep img{
      width: 100%;
  }
}
</style>
